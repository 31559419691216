import { CloseButton } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './Drawer.module.scss';
import { Props } from './Drawer.types';

const Drawer = React.forwardRef<PopupActions, Props>(
  ({
    icon,
    iconClassName,
    crossClassName,
    title,
    titleClassName,
    children,
    headerContent,
    headerClassName,
    onClose,
  }: Props) => {
    return (
      <>
        <div
          className={styles.overlay}
          onClick={event => {
            const targetElement = event.target as Element;
            if (!targetElement.closest(`.${styles.container}`)) {
              onClose();
            }
          }}
        >
          <div className={styles.container}>
            <div className={classnames(styles.icon, iconClassName)}>{icon}</div>
            <CloseButton
              onClick={() => {
                onClose();
              }}
              className={classnames(styles.cross, crossClassName)}
            />

            <div className={styles.header}>
              <div className={classnames(styles.title, titleClassName)}>{title}</div>
              <div className={headerClassName}>{headerContent}</div>
            </div>

            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </>
    );
  }
);

export default Drawer;

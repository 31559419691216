import { TextField, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import 'moment/locale/en-gb';
import { Calendrier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import { DatePicker } from '../../Calendars';
import Modal from '../../Modals/Modal/Modal.component';
import Popup from '../../Popups/Popup/Popup.component';

import styles from './DatePickerInput.module.scss';
import { Props } from './DatePickerInput.types';

const DatePickerInput = ({
  date,
  className,
  onSelectDate,
  minDate,
  maxDate,
  position,
  ...rest
}: Props) => {
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery('(max-device-width: 768px)');
  const mobileModalRef = useRef<PopupActions>(null);
  const webModalRef = useRef<PopupActions>(null);
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            display: 'none',
          },
        },
      },
    },
  });
  return (
    <>
      {isMobile ? (
        <Modal
          title={<></>}
          hideCloseAction
          ref={mobileModalRef}
          trigger={
            <div
              className={classnames(styles.datePickerContainer, {
                [styles.datePickerContainerFocused]: opened,
                className,
              })}
            >
              <div
                className={classnames(
                  { [styles.dateValid]: date !== undefined },
                  { [styles.dateNotValid]: date === undefined },
                  styles.subContainer
                )}
              >
                {date ? moment(date).format('L') : 'jj/mm/aaaa'}
              </div>
              <div className={styles.calendarIcon}>
                <Calendrier />
              </div>
            </div>
          }
        >
          <div className={styles.mobileCalendarContainer}>
            <DatePicker
              selected={date}
              onChange={date => {
                onSelectDate(date ?? undefined);
                mobileModalRef.current?.close();
              }}
              minDate={minDate}
              maxDate={maxDate}
              {...rest}
            />
          </div>
        </Modal>
      ) : (
        <div
          className={classnames(styles.datePickerContainer, {
            [styles.datePickerContainerFocused]: opened,
            className,
          })}
        >
          <div className={styles.datePickerContainerTrigger}>
            <Popup
              arrow={false}
              position={position}
              ref={webModalRef}
              trigger={<div className={styles.datePickerContainerTrigger}></div>}
              onClose={() =>
                setTimeout(() => {
                  opened && setOpened(false);
                }, 100)
              }
              open={opened}
              nested={false}
            >
              <DatePicker
                selected={date}
                onChange={date => {
                  onSelectDate(date ?? undefined);
                  webModalRef.current?.close();
                }}
                minDate={minDate}
                maxDate={maxDate}
                {...rest}
              />
            </Popup>
          </div>
          <div
            className={classnames(
              { [styles.dateValid]: date !== undefined },
              { [styles.dateNotValid]: date === undefined }
            )}
          >
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={date ?? null}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={event => {
                    const date = moment(event);
                    if (date) {
                      if (date.isValid()) {
                        onSelectDate(date.toDate());
                      }
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      type="date"
                      {...params}
                      inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                    />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
          <div
            className={classnames(styles.calendarIcon, styles.cursor)}
            onClick={() => {
              setOpened(!opened);
            }}
          >
            <Calendrier />
          </div>
        </div>
      )}
    </>
  );
};

export default DatePickerInput;

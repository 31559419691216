import {
  ChevronLeft,
  ChevronRight,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../Buttons';

import styles from './SegmentedControl.module.scss';
import { Props } from './SegmentedControl.types';

function SegmentedControl<T>({
  selected,
  controls,
  onSelectionChange,
  getValue,
  className,
  labelClassName,
  selectedBackgroundClassName,
}: Props<T>) {
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const childRefs = useRef<(HTMLLabelElement | null)[]>([]);
  const selectedBackgroundRef = useRef<HTMLDivElement>(null);
  const [contained, setContained] = useState<boolean>(false);

  const getDistanceToBase = useCallback((index: number): number => {
    return childRefs.current.reduce((previousValue, childRef, currentIndex) => {
      if (childRef && currentIndex < index) {
        return previousValue + childRef.getBoundingClientRect().width;
      }
      return previousValue;
    }, 0);
  }, []);

  const handleMovement = useCallback(() => {
    if (mainContainerRef.current) {
      childRefs.current.forEach((childRef, index) => {
        if (childRef) {
          if (selectedBackgroundRef.current && mainContainerRef.current) {
            if (index === controls.indexOf(selected)) {
              const distance = getDistanceToBase(index);
              const childWidth = childRef.getBoundingClientRect().width;
              selectedBackgroundRef.current.style.width = `${childWidth}px`;
              selectedBackgroundRef.current.style.transform = `translateX(${distance}px)`;
              if (contained) {
                mainContainerRef.current.scrollTo({
                  behavior: 'smooth',
                  left: Math.max(
                    distance -
                      mainContainerRef.current.getBoundingClientRect().width / 2 +
                      childWidth / 2,
                    0
                  ),
                });
              }
            }
          }
        }
      });
      const totalChildrenWidth = childRefs.current.reduce((accumulator, childRef) => {
        if (childRef) {
          return accumulator + childRef.getBoundingClientRect().width;
        }
        return accumulator;
      }, 0);
      if (totalChildrenWidth - 1 > mainContainerRef.current.getBoundingClientRect().width) {
        setContained(true);
      } else {
        setContained(false);
      }
    }
  }, [controls, getDistanceToBase, selected, contained]);

  useEffect(() => {
    handleMovement();
  }, [controls, getDistanceToBase, handleMovement, selected]);

  useEffect(() => {
    setTimeout(handleMovement);
  }, [contained, handleMovement]);

  useEffect(() => {
    window.addEventListener('resize', handleMovement);
    return () => {
      window.removeEventListener('resize', handleMovement);
    };
  }, [handleMovement]);

  return (
    <div className={styles.mainContainer}>
      {contained && (
        <Button.XSmall
          className={styles.button}
          onClick={() => {
            const index = controls.indexOf(selected);
            onSelectionChange(controls[(index + controls.length - 1) % controls.length]);
          }}
        >
          <ChevronLeft />
        </Button.XSmall>
      )}
      <div className={classnames(styles.container, className)} ref={mainContainerRef}>
        {controls.map((control, index) => (
          <label
            key={`${control}`}
            className={classnames(styles.control, labelClassName, {
              [styles.selected]: selected === control,
            })}
            ref={el => {
              childRefs.current[index] = el;
            }}
          >
            <span className={styles.text}>{getValue(control)}</span>
            <input
              className={styles.input}
              type="radio"
              name="radioBtn"
              onChange={() => {
                onSelectionChange(control);
              }}
            />
          </label>
        ))}
        <div
          ref={selectedBackgroundRef}
          className={classnames(styles.selectedBackground, selectedBackgroundClassName)}
        >
          <div />
        </div>
      </div>
      {contained && (
        <Button.XSmall
          className={styles.button}
          onClick={() => {
            const index = controls.indexOf(selected);
            onSelectionChange(controls[(index + 1) % controls.length]);
          }}
        >
          <ChevronRight />
        </Button.XSmall>
      )}
    </div>
  );
}

export default SegmentedControl;

import {
  Step,
  StepConnector,
  StepLabel,
  Stepper as MuiStepper,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import {
  CircleLine,
  BulletFill,
  CircleCheck,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React from 'react';

import styles from './Stepper.module.scss';
import { IconProps, Props } from './Stepper.types';

const CustomStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: styles.completedcolor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: styles.completedcolor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: styles.defaultcolor,
  },
}));

const Stepper = ({
  fields,
  selectedkey,
  selected,
  displayColumn,
  titleUnderBullet,
  complete,
  onSelectionChange,
}: Props) => {
  const isComplete: boolean[] = complete ?? fields.map(_ => false);
  const reversed: boolean = (titleUnderBullet && displayColumn) ?? false;

  const getTitleClassname = (index: number) => {
    return isComplete[index]
      ? styles.completed
      : index <= selectedkey.indexOf(selected ?? '')
      ? styles.selected
      : styles.notSelected;
  };

  const getIconTitle = (iconProps: IconProps) => {
    return (
      <>
        <iconProps.IconComponent className={iconProps.iconClassname} />
        {reversed && fields[iconProps.index]}
      </>
    );
  };

  const getIcon = (index: number) => {
    return (
      <div
        className={classNames(getTitleClassname(index), reversed ? styles.reversed : styles.icon)}
      >
        {isComplete[index]
          ? getIconTitle({
              index,
              IconComponent: CircleCheck,
              iconClassname: styles.completeIcon,
            })
          : index <= selectedkey.indexOf(selected ?? '')
          ? getIconTitle({ index, IconComponent: BulletFill })
          : getIconTitle({ index, IconComponent: CircleLine, iconClassname: styles.circleLine })}
      </div>
    );
  };

  return (
    <MuiStepper
      activeStep={selectedkey.indexOf(selected ?? '')}
      orientation={displayColumn ? 'vertical' : 'horizontal'}
      className={classNames(styles.container, reversed ? styles.reversed : undefined)}
      alternativeLabel={titleUnderBullet && !displayColumn}
      connector={<CustomStepConnector />}
    >
      {selectedkey.map((key, index: number) => (
        <Step key={fields[index]} onClick={() => onSelectionChange(key)}>
          <StepLabel
            className={titleUnderBullet && !displayColumn ? styles.titleUnderIcon : undefined}
            icon={getIcon(index)}
            classes={{ active: styles.selectedLabel, completed: styles.completedLabel }}
          >
            {!reversed && fields[index]}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;

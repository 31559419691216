import {
  AddCrossPartial,
  MinusBar,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { ReactNode, useState } from 'react';

import Button from '../Buttons';

import styles from './Table.module.scss';
import { Props, LineData } from './Table.types';

const Table: React.FC<Props> = ({
  headers,
  data,
  className,
  lineClassName,
  nestedLineClassName,
  expandedLineClassName,
  expandHeaderLabel,
  hasFixedHeaders,
  lineActions,
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleExpand = (index: number) => {
    setExpandedRows(prevState =>
      prevState.includes(index) ? prevState.filter(x => x !== index) : [...prevState, index]
    );
  };

  const handleParentRowClick = (index: number, hasNested: boolean) => {
    if (hasNested) {
      toggleExpand(index);
    }
    if (lineActions) {
      lineActions(index);
    }
  };

  const renderRow = (
    line: ReactNode[],
    index: number,
    isNested = false,
    hasNested = false,
    isLastChild = false,
    parentRowWithNoChildrenRightComponent?: ReactNode,
    childRowRightComponent?: ReactNode
  ) => (
    <tr
      className={
        isNested
          ? classnames(nestedLineClassName, isLastChild ? styles.lastNested : styles.notLastNested)
          : expandedRows.includes(index)
          ? classnames(
              lineClassName,
              styles.noBorderParent,
              expandedLineClassName ?? styles.expandedLine
            )
          : classnames(lineClassName, styles.collapsedParentRow)
      }
      onClick={() => handleParentRowClick(index, hasNested)}
    >
      {line.map((element, i) => (
        <td style={{ width: headers[i]?.width || 'auto' }}>{element}</td>
      ))}
      {Array.from({ length: headers.length - line.length }, (_, i) => (
        <td key={`placeholder-${i}`}></td>
      ))}
      <td>
        {!isNested && hasNested && (
          <Button.Tertiary className={styles.iconButton}>
            {expandedRows.includes(index) ? <MinusBar /> : <AddCrossPartial />}
          </Button.Tertiary>
        )}
        {!isNested && !hasNested && parentRowWithNoChildrenRightComponent}
        {isNested && childRowRightComponent}
      </td>
    </tr>
  );

  return Array.isArray(data[0]) && Array.isArray((data as ReactNode[][])[0][0]) ? (
    <table className={classnames(styles.container, className)}>
      <thead className={hasFixedHeaders ? styles.fixedHeader : ''}>
        <tr>
          {headers.map(label => (
            <th style={{ width: label.width }}>
              <div className={styles.header}>{label.label}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data as ReactNode[][]).map((line, index) => (
          <tr className={lineClassName} onClick={() => handleParentRowClick(index, false)}>
            {line.map(element => (
              <td>{element}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <table className={classnames(styles.container, className)}>
      <thead className={hasFixedHeaders ? styles.fixedHeader : ''}>
        <tr>
          {headers.map(label => (
            <th style={{ width: label.width }}>
              <div className={styles.header}>{label.label}</div>
            </th>
          ))}
          <th className={styles.header}>{expandHeaderLabel}</th>
        </tr>
      </thead>
      <tbody>
        {(data as LineData[]).map((lineData, index) =>
          Array.isArray(lineData) ? (
            renderRow(lineData, index)
          ) : (
            <>
              {renderRow(
                lineData.line,
                index,
                false,
                !!lineData.nested,
                false,
                lineData.parentRowWithNoChildrenRightComponent,
                lineData.childRowRightComponent
              )}
              {expandedRows.includes(index) &&
                lineData.nested &&
                lineData.nested.map((nestedLine, nestedIndex) =>
                  renderRow(
                    nestedLine,
                    index,
                    true,
                    false,
                    nestedIndex === (lineData.nested?.length ?? 0) - 1,
                    lineData.parentRowWithNoChildrenRightComponent,
                    lineData.childRowRightComponent
                  )
                )}
            </>
          )
        )}
      </tbody>
    </table>
  );
};

export default Table;
